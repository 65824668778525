<template>
  <div>
    <div class="main-header">
      <div class="title">活动管理</div>
    </div>
    <div class="main-main">
      <a-card>
        <div class="search-card">
          <div class="search-row">
            <div class="search-option">
              <span class="label">活动名称</span>
              <a-input
                placeholder="请输入活动名称"
                v-model="searchActivityName"
              ></a-input>
            </div>
            <div class="search-option">
              <span class="label">活动类型</span>
              <a-select
                allowClear
                placeholder="请选择活动类型"
                class="search-select"
                v-model="searchType"
                :options="allActivityType"
              >
              </a-select>
            </div>
            <div class="search-option" style="flex: 1.5">
              <span class="label">所属组织</span>
              <a-tree-select
                allowClear
                class="search-select"
                v-model="searchGroupID"
                :tree-data="groupTree"
                placeholder="请选择所属组织"
              >
              </a-tree-select>
            </div>
            <div class="empty">
              <a-button type="primary" @click="searchByOptions" icon="search"
                >搜索</a-button
              >
            </div>
            <div class="empty"></div>
          </div>
        </div>
        <div class="optionsAndPagination"></div>
        <div class="actions">
          <div class="btns" style="gap: 10px">
            <a-button icon="plus-circle" @click="addActivity">新增</a-button>
            <a-button icon="minus-circle" @click="delActivity">删除</a-button>
          </div>
          <Pagination
            @refresh="refresh"
            @pageChange="pageChange"
            @pageSizeChange="pageSizeChange"
            :total="total"
            :page="searchOptions.page"
          ></Pagination>
        </div>
        <div>
          <a-table
            size="middle"
            :loading="tableLoading"
            :data-source="tableData"
            :columns="tableColumns"
            :pagination="false"
            :scroll="{ x: 10 }"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
          >
            <template slot="activityDate" slot-scope="activityDate">
              <div v-for="(time, index) in activityDate" :key="index">
                {{ time.format("YYYY-MM-DD") }}
              </div>
            </template>
            <template slot="join" slot-scope="join, row">
              <a @click="showJoin(row)">{{ join }}</a>
            </template>
            <template slot="createTime" slot-scope="createTime">
              {{ createTime }}
            </template>
            <template slot="timeCoin" slot-scope="timeCoin, row">
              <span
                :class="{ red: row.moneyType == 0, green: row.moneyType == 1 }"
              >
                {{ row.moneyType == 0 ? "+" : "-" }}{{ timeCoin }}
              </span>
            </template>
            <template slot="status" slot-scope="status">
              {{ !status ? "上线" : "下线" }}
            </template>
            <template slot="action" slot-scope="row">
              <div style="display: flex; gap: 10px; justify-content: center">
                <a @click="readEditActivity(row)">查看</a
                ><a @click="changeStatus(row)">{{
                  !row.status ? "下线" : "上线"
                }}</a>
                <a @click="showJoin(row)"> 活动参加列表</a>
                <a @click="delActivity(row)">删除</a>
                <a @click="downloadQr(row)">生成活动二维码</a>
              </div>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>
    <a-modal
      v-model="showJoinModal"
      :footer="null"
      title="活动参加列表"
      :closable="false"
      :width="700"
    >
      <div class="activity-info">
        <div>
          <span>活动日期：</span>
          <a-select
            :options="activityLists"
            v-model="selectDate"
            @change="joinTimeChange"
          ></a-select>
        </div>
        <div>
          <span>活动名称：</span>
          <span>{{ activityName }}</span>
        </div>
        <div>
          <span>消耗/获取时间币：</span>
          <span>{{ jjj }}</span>
        </div>
      </div>
      <a-table
        :loading="tableLoading2"
        :data-source="joinTableData"
        :columns="joinTableColumn"
        :pagination="false"
        :scroll="{ y: 300 }"
      >
        <template slot="joinTime" slot-scope="joinTime">
          {{ joinTime }}
        </template>
        <template slot="joinStatus" slot-scope="joinStatus, userId">
          {{ formatJoinStatus(joinStatus) }}
          <a style="color: #1890ff; margin-left: 10px" @click="htBtn(userId)"
            >回退</a
          >
        </template>
      </a-table>
      <div class="modal-btn">
        <a-button icon="plus" style="margin-right: 20px" @click="addPeople"
          >补录</a-button
        >
        <a-button
          icon="download"
          type="primary"
          style="margin-right: 20px"
          @click="downloadJoin"
          :loading="downloadLoading"
          >导出名单
          <!-- <vue-excel-xlsx
            class="excel-btn"
            :columns="joinExcelColumns"
            :sheetname="joinExcleName"
            :filename="joinExcleName"
            :data="joinTableData"
          >
          </vue-excel-xlsx> -->
        </a-button>
        <a-button icon="form" @click="openActivityRecord"> 活动纪要 </a-button>
      </div>
    </a-modal>
    <div id="qrcode" style="display: none"></div>
    <a-modal
      v-model="addActivityForm"
      :footer="null"
      :title="modalTitle"
      :closable="false"
      :width="900"
    >
      <a-form-model
        :labelCol="{ span: 7 }"
        :wrapperCol="{ span: 17 }"
        class="activity-form"
        :model="addForm"
        :rules="rules"
        ref="addForm"
      >
        <a-form-model-item label="活动标题" prop="activityName">
          <a-input v-model="addForm.activityName"></a-input>
        </a-form-model-item>
        <a-form-model-item label="活动分类" prop="type">
          <a-select
            :options="allActivityType"
            v-model="addForm.type"
          ></a-select>
        </a-form-model-item>
        <a-form-model-item label="活动开始时间" prop="startTime">
          <a-time-picker
            :allowClear="false"
            format="HH:mm"
            valueFormat="HH:mm"
            :value="addForm.startTime"
            style="width: 100%"
            @change="changeSt"
          ></a-time-picker>
        </a-form-model-item>
        <a-form-model-item label="活动结束时间" prop="endTime">
          <a-time-picker
            :allowClear="false"
            format="HH:mm"
            valueFormat="HH:mm"
            :value="addForm.endTime"
            style="width: 100%"
            @change="changeEt"
          ></a-time-picker>
        </a-form-model-item>
        <a-form-model-item label="联系人" prop="linkman">
          <a-input v-model="addForm.linkman"></a-input>
        </a-form-model-item>
        <a-form-model-item label="联系方式" prop="tel">
          <a-input v-model="addForm.tel" type="number"></a-input>
        </a-form-model-item>
        <a-form-model-item label="发币扣币" prop="moneyType">
          <a-select :options="getOrgive" v-model="addForm.moneyType"></a-select>
        </a-form-model-item>
        <a-form-model-item label="时间币个数" prop="timeCoin">
          <a-input v-model="addForm.timeCoin" type="number"></a-input>
        </a-form-model-item>
        <a-form-model-item label="活动地点" prop="address">
          <a-input
            v-model="addForm.address"
            :disabled="true"
            placeholder="请在下方地图选择地点"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="活动人数" prop="peopleCount">
          <a-input v-model="addForm.peopleCount" type="number"></a-input>
        </a-form-model-item>
        <div style="width: 100%; position: relative; margin-bottom: 10px">
          <div id="map-container"></div>
          <div class="search-container">
            <a-input v-model="searchKeyWord" @input="searchMap"></a-input>
            <div class="search-list" v-if="searchResult.length">
              <div
                v-for="(item, index) in searchResult"
                :key="index"
                @click="choseAddress(item)"
                class="search-item"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <a-form-model-item label="活动时数(小时)" prop="gainTime">
          <a-input v-model="addForm.gainTime" type="number"></a-input>
        </a-form-model-item>
        <a-form-model-item label="活动日期" prop="activityDate">
          <div
            style="
              display: flex;
              gap: 10px;
              padding-top: 4px;
              margin-bottom: 10px;
            "
          >
            <a-date-picker
              @change="onChange"
              style="flex: 1"
              :value="addFormSelectedDate"
            ></a-date-picker>
            <a-button
              icon="plus"
              style="width: 38px"
              type="primary"
              @click="addDate"
            ></a-button>
          </div>
          <div>
            <a-row
              style="height: 40px"
              v-for="(item, index) in addForm.activityDate"
              :key="index"
            >
              <a-col :span="6"></a-col>
              <a-col
                :span="18"
                style="display: flex; justify-content: space-between"
                >{{ item.format("YYYY-MM-DD") }}
                <a-button
                  icon="minus"
                  style="width: 38px"
                  @click="delAddFormActivityDate(index)"
                ></a-button
              ></a-col>
            </a-row>
          </div>
        </a-form-model-item>
        <a-form-model-item label="招募图片上传" prop="imageUrl">
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            action="https://upload-z0.qiniup.com"
            :data="{ token: uploadToken, key: uploadKey }"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <img
              v-if="addForm.imageUrl"
              :src="addForm.imageUrl"
              alt="avatar"
              style="width: 100px; width: 100px"
            />
            <div v-else>
              <a-icon :type="uploading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">请选择文件</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label=""> </a-form-model-item>
        <div id="rich-text" style="flex: 100%"></div>
        <div style="flex: 100%; text-align: center; margin-top: 15px">
          <a-button
            type="primary"
            @click="addActivitySubmit"
            :disabled="buttonDisable"
            >保存提交</a-button
          >
        </div>
      </a-form-model>
    </a-modal>
    <a-modal
      title="补录"
      :footer="null"
      :closable="false"
      v-model="addPeopleModal"
    >
      <div class="search-row">
        <a-input
          placeholder="请输用户名"
          v-model="peopleSearchKeyword"
        ></a-input>
        <a-button type="primary" @click="searchPeople">查询</a-button>
        <a-button @click="resetPeopleSearch">重置</a-button>
      </div>
      <div class="search-result-container" v-if="peopleSearchResult.length">
        <div
          v-for="(item, index) in peopleSearchResult"
          :key="index"
          class="search-result"
        >
          <span>{{ item.nickName }}</span>
          <div class="search-result-btn">
            <a-button
              type="primary"
              @click="addThisPeople(item.id)"
              size="small"
              >补录</a-button
            >
          </div>
        </div>
      </div>
      <div v-else style="margin: 20px 0">
        <a-empty />
      </div>
    </a-modal>
    <a-modal
      v-model="activityRecordModal"
      title="活动纪要"
      :footer="null"
      :closable="false"
    >
      <a-form-model :labelCol="{ span: 5 }" :wrapperCol="{ span: 18 }">
        <a-form-model-item label="活动纪要">
          <a-textarea v-model="tmpForm.activityResult"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="活动照片">
          <a-upload
            accept=".jpg,.jpeg,.png,.bmp"
            action="https://upload-z0.qiniup.com"
            :data="{ token: uploadToken, key: uploadKey }"
            list-type="picture-card"
            :file-list="tmpForm.picList"
            @preview="previewFile"
            @change="handleChange2"
            :before-upload="beforeUpload2"
          >
            <div v-if="tmpForm.picList.length < 9">
              <a-icon :type="uploading2 ? 'loading' : 'plus'" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <div>活动照片大小限制2M下</div>
        </a-form-model-item>
      </a-form-model>
      <div style="text-align: center">
        <a-button type="primary" @click="submit2" :disabled="submitDisable2"
          >保存</a-button
        >
      </div>
    </a-modal>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import E from "wangeditor";
import {
  getSevenNiuToken,
  getActivityList,
  editActivity,
  addActivity,
  delActivity,
  getJoinList,
  getUserList,
  addPeople,
  getOptions,
  downloadActivityResult,
  getActivityResult,
  saveActivityResult,
  huiTui,
} from "@/network/api";
import QRCode from "qrcodejs2";
import Pagination from "../components/Pagination";
import { mapState } from "vuex";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  components: {
    Pagination,
  },
  methods: {
    submit2() {
      if (this.submitDisable2) {
        return;
      }
      this.submitDisable2 = true;
      const tmp = JSON.parse(JSON.stringify(this.tmpForm));
      console.log(tmp);
      tmp.picList = tmp.picList.map((item) => {
        if (item.url) {
          return item.url;
        } else {
          return this.uploadUrl + item.response.key;
        }
      });
      saveActivityResult({ ...tmp, activityId: this.selectedActivityId }).then(
        (res) => {
          if (res.status == 0) {
            this.$message.success("保存成功");
            this.activityRecordModal = false;
          } else {
            this.submitDisable2 = false;
          }
        }
      );
    },
    handleCancel() {
      this.previewVisible = false;
    },
    removeFile() {},
    async previewFile(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange2(info) {
      if (info.file.status === "uploading") {
        this.uploading2 = true;
        this.$set(this.tmpForm, "picList", [...info.fileList]);
        return;
      }
      if (info.file.status === "done") {
        this.uploading2 = false;
        this.getUploadToken();
      }
      this.$set(this.tmpForm, "picList", [
        ...info.fileList.filter((item) => item.status === "done"),
      ]);
    },
    openActivityRecord() {
      getActivityResult({
        activityId: this.selectedActivityId,
      }).then((res) => {
        this.tmpForm.activityResult = res.data.activityResult;
        this.tmpForm.picList = res.data.activityPicture.map((item) => {
          return {
            status: "done",
            name: item.split("/").slice(-1)[0],
            url: item,
            uid: item.split("/").slice(-1)[0],
          };
        });
        console.log(this.tmpForm);
      });
      this.activityRecordModal = true;
      this.submitDisable2 = false;
    },
    downloadJoin() {
      this.downloadLoading = true;
      downloadActivityResult({
        id: this.selectedActivityId,
        date: this.$dayjs(this.selectDate).format("YYYY-MM-DD"),
      })
        .then((res) => {
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = window.URL.createObjectURL(res);
          link.setAttribute(
            "download",
            `${this.activityName}${this.$dayjs(this.selectDate).format(
              "YYYY-MM-DD"
            )}.doc`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
    //回退
    async htBtn(row) {
      console.log(row, 111);
      console.log(this.selectedActivityId, 222);
      this.$confirm({
        title: "确定要回退该用户吗？",
        onOk: async () => {
          const data = await huiTui({
            activityId: this.selectedActivityId,
            userId: row.userId,
          });
          if (data.status === 0) {
            this.$message.success(data.msg);
            getJoinList({
              id: this.selectedActivityId,
              date: this.$dayjs(this.selectDate).format("YYYY-MM-DD"),
            }).then((res) => {
              this.joinTableData = res.data.content.map((item) => {
                item.name = item.nickName;
                item.phoneNum = item.phone;
                item.joinTime = item.signInTime;
                item.joinStatus = item.status ? 4 : item.userType;
                return item;
              });
            });
          } else {
            this.$message.error(data.msg);
          }
        },
      });

      console.log(data, 333);
    },

    addThisPeople(userId) {
      addPeople({
        type: 1,
        signTime:
          this.$dayjs(this.selectDate).format("YYYY-MM-DD") + " 00:00:00",
        activityId: this.selectedActivityId,
        userId,
      }).then((res) => {
        if (res.status == 0) {
          this.$message.success("补录成功！");
          this.getActivityList(this.searchOptions);
          getJoinList({
            id: this.selectedActivityId,
            date: this.$dayjs(this.selectDate).format("YYYY-MM-DD"),
          }).then((res) => {
            console.log(res.data.content, 222);
            this.joinTableData = res.data.content.map((item) => {
              item.name = item.nickName;
              item.phoneNum = item.phone;
              item.joinTime = item.signInTime;
              item.joinStatus = item.status ? 4 : item.userType;
              item.userId = item.userId;
              return item;
            });
            this.tableLoading2 = false;
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    searchPeople() {
      getUserList({
        nickName: this.peopleSearchKeyword,
        page: 1,
        pageSize: 999,
      }).then((res) => {
        if (res.status == 0) {
          this.peopleSearchResult = res.data.content.map((item) => {
            return item;
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetPeopleSearch() {
      this.peopleSearchKeyword = "";
      this.peopleSearchResult = [];
    },
    addPeople() {
      this.addPeopleModal = true;
    },
    formatJoinStatus(val) {
      if (val == 1) {
        return "用户扫码";
      }
      if (val == 4) {
        return "补录";
      } else {
        return "管理员扫码";
      }
    },
    readEditActivity(row) {
      console.log(row);
      (this.addFormSelectedDate = null),
        (this.addFormSelectedDateList = row.activityDate);
      this.addForm = {
        ...row,
      };
      this.modalTitle = "查看活动";
      this.uploading = false;
      this.buttonDisable = false;
      if (row.status == 0) {
        this.buttonDisable = true;
      }
      this.addActivityForm = true;
      this.$nextTick(() => {
        this.initMap(row.latitude, row.longitude);
        this.initRichText();
        this.richText.txt.html(row.activityIntroduce);
        this.$refs.addForm.clearValidate();
      });
    },
    pageChange(page, pageSize) {
      this.searchOptions.page = page;
      this.getActivityList(this.searchOptions);
    },
    pageSizeChange(page, pageSize) {
      this.searchOptions.page = 1;
      this.searchOptions.pageSize = pageSize;
      this.getActivityList(this.searchOptions);
    },

    refresh() {
      this.getActivityList();
    },
    addActivitySubmit() {
      this.$refs.addForm.validate((res) => {
        if (res) {
          if (this.modalTitle == "新增活动") {
            this.$set(
              this.addForm,
              "activityIntroduce",
              this.richText.txt.html()
            );
            this.$set(this.addForm, "pics", this.richText.txt.html());
            let newDateList = [];
            this.addFormSelectedDateList.forEach((item) => {
              newDateList.push(item.format("YYYY-MM-DD"));
            });
            this.$set(this.addForm, "dates", newDateList);
            this.$set(this.addForm, "timeMoney", this.addForm.timeCoin);
            this.$set(this.addForm, "pics", [this.addForm.imageUrl]);
            this.buttonDisable = true;
            addActivity(this.addForm).then((res) => {
              if (res.status == 0) {
                this.getActivityList(this.searchOptions);
                this.addActivityForm = false;
                this.$message.success("新增成功！");
              } else {
                this.buttonDisable = false;
                this.$message.error("新增失败！");
              }
            });
          } else {
            this.$set(
              this.addForm,
              "activityIntroduce",
              this.richText.txt.html()
            );
            let newDateList = [];
            this.addFormSelectedDateList.forEach((item) => {
              newDateList.push(item.format("YYYY-MM-DD"));
            });
            this.$set(this.addForm, "dates", newDateList);
            this.$set(this.addForm, "timeMoney", this.addForm.timeCoin);
            this.$set(this.addForm, "pics", [this.addForm.imageUrl]);
            this.buttonDisable = true;
            console.log(this.addForm.pics);
            editActivity(this.addForm).then((res) => {
              if (res.status == 0) {
                this.getActivityList(this.searchOptions);
                this.addActivityForm = false;
                this.$message.success("编辑成功！");
              } else {
                this.buttonDisable = false;
                this.$message.error("编辑失败！");
              }
            });
          }
        }
      });
    },
    handleChange(info) {
      console.log(info);
      if (info.file.status === "uploading") {
        this.uploading = true;
      }
      if (info.file.status === "done") {
        this.$set(
          this.addForm,
          "imageUrl",
          this.uploadUrl + info.file.response.key
        );
        this.getUploadToken();
      }
    },
    getUploadToken() {
      return new Promise((resolve, reject) => {
        getSevenNiuToken("https://m2.zyh365.com/").then((res) => {
          this.uploadToken = res.data.token;
          this.uploadKey = res.data.key;
          this.uploadUrl = res.data.url;
          resolve();
        });
      });
    },
    beforeUpload(file) {
      console.log(file);
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("请上传jpg或png格式图片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片不能大于2MB!");
      }
      return isJpgOrPng && isLt2M && this.getUploadToken();
    },
    beforeUpload2(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片不能大于2MB!");
      }
      return isLt2M;
    },
    changeSt(e) {
      this.$set(this.addForm, "startTime", e);
      if (this.addForm.startTime > this.addForm.endTime) {
        var a = this.addForm.startTime;
        this.$set(this.addForm, "startTime", this.addForm.endTime);
        this.$set(this.addForm, "endTime", a);
      }
    },
    changeEt(e) {
      this.$set(this.addForm, "endTime", e);
      if (this.addForm.startTime > this.addForm.endTime) {
        var a = this.addForm.startTime;
        this.$set(this.addForm, "startTime", this.addForm.endTime);
        this.$set(this.addForm, "endTime", a);
      }
    },
    choseAddress(item) {
      this.$set(
        this.addForm,
        "address",
        (item.province ? item.province : "") +
          (item.province == item.city ? "" : item.city ? item.city : "") +
          item.title
      );
      this.$set(this.addForm, "point", item.point);
      this.$set(this.addForm, "longitude", item.point.lng);
      this.$set(this.addForm, "latitude", item.point.lat);
      const marker = new BMap.Marker(item.point);
      this.map.clearOverlays();
      this.map.addOverlay(marker);
      marker.setAnimation(BMAP_ANIMATION_BOUNCE);
      this.map.centerAndZoom(item.point, 15);
      this.searchResult = [];
    },
    searchMap() {
      this.searchResult = [];
      clearTimeout(this.timer);
      this.timer = null;
      if (!this.searchKeyWord) {
        return;
      }
      this.timer = setTimeout(() => {
        const mapSearch = new BMap.LocalSearch(this.map, {
          onSearchComplete: (results) => {
            // console.log(results);
            if (mapSearch.getStatus() == BMAP_STATUS_SUCCESS) {
              // 判断状态是否正确
              var s = [];
              for (var i = 0; i < results.getCurrentNumPois(); i++) {
                s.push(results.getPoi(i));
              }
              console.log(s);
              this.searchResult = s;
            } else {
              this.searchResult = [];
            }
          },
        });
        mapSearch.search(this.searchKeyWord);
      }, 1000);
    },
    initRichText() {
      if (!this.richText) {
        this.richText = new E("#rich-text");
        this.richText.config.height = 200;
        this.richText.config.uploadImgServer = "https://upload-z0.qiniup.com/";
        this.richText.config.uploadFileName = "file";
        this.richText.config.uploadImgParams = {
          token: this.uploadToken,
          key: this.uploadKey,
        };
        this.richText.config.uploadImgHooks = {
          // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
          customInsert: (insertImgFn, result) => {
            console.log(result.key);
            // result 即服务端返回的接口
            // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
            insertImgFn(this.uploadUrl + result.key);
            this.getUploadToken().then(() => {
              this.richText.config.uploadImgParams = {
                token: this.uploadToken,
                key: this.uploadKey,
              };
            });
          },
        };
        this.richText.config.excludeMenus = ["video"];
        this.richText.create();
      }
      this.richText.txt.clear();
    },
    initMap(lat, lng) {
      this.map = new BMap.Map("map-container");
      var point = new BMap.Point(116.331398, 39.897445);
      this.map.centerAndZoom(point, 12);
      this.map.addControl(new BMap.NavigationControl());

      this.map.addEventListener("click", (e) => {
        const marker = new BMap.Marker(e.point);
        // this.map.centerAndZoom(e.point, 15);
        this.map.clearOverlays();
        this.map.addOverlay(marker);
        marker.setAnimation(BMAP_ANIMATION_BOUNCE);
        const searchGeo = new BMap.Geocoder();
        searchGeo.getLocation(
          e.point,
          (x) => {
            this.$set(
              this.addForm,
              "address",
              x.address +
                (x.surroundingPois.length > 0 ? x.surroundingPois[0].title : "")
            );
          },
          {
            poiRadius: 100,
            numPois: 1,
          }
        );
        this.$set(this.addForm, "longitude", e.point.lng);
        this.$set(this.addForm, "latitude", e.point.lat);
      });
      if (!lat || !lng) {
        var geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition((r) => {
          if (geolocation.getStatus() == BMAP_STATUS_SUCCESS) {
            var mk = new BMap.Marker(r.point);
            this.map.addOverlay(mk);
            this.map.panTo(r.point);
          } else {
            this.$message.error("定位失败！");
          }
        });
      } else {
        var p = new BMap.Point(lng, lat);
        var mk = new BMap.Marker(p);
        this.map.addOverlay(mk);
        this.map.panTo(p);
      }
    },
    delAddFormActivityDate(e) {
      this.addFormSelectedDateList.splice(e, 1);
      console.log(this.addFormSelectedDateList);
      this.$set(this.addForm, "activityDate", this.addFormSelectedDateList);
    },
    addDate() {
      let flag = true;
      if (this.addFormSelectedDate) {
        this.addFormSelectedDateList.forEach((item) => {
          if (
            item.format("YYYY-MM-DD") ==
            this.$dayjs(this.addFormSelectedDate).format("YYYY-MM-DD")
          ) {
            flag = false;
            return this.$message.warning("已添加过该日期！");
          }
        });
        if (flag) {
          this.addFormSelectedDateList.push(this.addFormSelectedDate);
          this.$set(this.addForm, "activityDate", this.addFormSelectedDateList);
          console.log(this.addFormSelectedDateList);
        }
      } else {
        this.$message.warning("请选择日期！");
      }
    },
    onChange(e) {
      this.addFormSelectedDate = e;
    },
    delActivity(row) {
      if (row.id || row.id == 0) {
        this.$confirm({
          title: "确定要删除该活动吗？",
          onOk: () => {
            return new Promise((resolve, reject) => {
              delActivity({ ids: [row.id] }).then((res) => {
                if (res.status == 0) {
                  this.$message.success("删除成功！");
                  this.searchOptions.page = 1;
                  this.getActivityList(this.searchOptions);
                  resolve();
                } else {
                  this.$message.error("删除失败！");
                }
              });
            });
          },
        });
      } else {
        if (!this.selectedRowKeys.length) {
          return this.$message.error("您还未选中要删除的活动！");
        }
        this.$confirm({
          title: "确定要删除该活动吗？",
          onOk: () => {
            return new Promise((resolve, reject) => {
              delActivity({
                ids: this.selectedRowList.map((item) => item.id),
              }).then((res) => {
                if (res.status == 0) {
                  this.$message.success("删除成功！");
                  this.searchOptions.page = 1;
                  this.getActivityList(this.searchOptions);
                  resolve();
                } else {
                  this.$message.error("删除失败！");
                }
              });
            });
          },
        });
      }
    },
    addActivity() {
      this.addFormSelectedDate = null;
      this.addFormSelectedDateList = [];
      this.addForm = {};
      this.uploading = false;
      this.modalTitle = "新增活动";
      this.buttonDisable = false;
      this.searchKeyWord = "";
      this.addActivityForm = true;
      this.$nextTick(() => {
        this.initMap();
        this.initRichText();
        this.$refs.addForm.clearValidate();
      });
    },
    downloadQr(e) {
      console.log(e);
      if (!this.newqrcode) {
        this.newqrcode = new QRCode("qrcode", {
          width: 150, //图像宽度
          height: 150, //图像高度
          colorDark: "#000000", //前景色
          colorLight: "#ffffff", //背景色
          typeNumber: 4,
          correctLevel: QRCode.CorrectLevel.H, //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
        });
      }
      this.newqrcode.clear(); //清除二维码
      this.newqrcode.makeCode(e.id + "");
      let a = document.createElement("a");
      a.href = this.newqrcode._oDrawing._elCanvas.toDataURL();
      a.download = e.activityName;
      a.click();
    },
    changeStatus(e) {
      this.$confirm({
        title: `确定要${!e.status ? "下线" : "上线"}该活动吗？`,
        onOk: () => {
          return new Promise((resolve, reject) => {
            var pa = {
              ...e,
            };
            pa.status = pa.status == 1 ? 0 : 1;
            editActivity(pa).then((res) => {
              if (res.status == 0) {
                this.$message.success(`${!e.status ? "下线" : "上线"}成功！`);
                this.getActivityList(this.searchOptions);
                resolve();
              } else {
                this.$message.error(`${!e.status ? "下线" : "上线"}失败！`);
              }
            });
          });
        },
      });
    },
    del(e) {
      console.log(e);
    },
    joinTimeChange(e) {
      this.joinExcleName =
        this.activityName + this.$dayjs(e).format("YYYY-MM-DD") + "参与人数表";
      this.tableLoading2 = true;
      getJoinList({
        id: this.selectedActivityId,
        date: this.$dayjs(this.selectDate).format("YYYY-MM-DD"),
      }).then((res) => {
        this.joinTableData = res.data.content.map((item) => {
          item.name = item.nickName;
          item.phoneNum = item.phone;
          item.joinTime = item.signInTime;
          item.joinStatus = item.status ? 4 : item.userType;
          return item;
        });
        this.tableLoading2 = false;
      });
    },
    showJoin(e) {
      this.selectedActivityId = e.id;
      this.activityLists = [];
      this.activityName = e.activityName;
      this.jjj = e.moneyType ? "-" + e.timeMoney : "+" + e.timeMoney;
      this.selectDate = this.$dayjs(e.activityDate[0]).valueOf();
      this.joinExcleName =
        this.activityName +
        this.$dayjs(this.selectDate).format("YYYY-MM-DD") +
        "参与人数表";
      e.activityDate.forEach((item) => {
        this.activityLists.push({
          title: this.$dayjs(item).format("YYYY-MM-DD"),
          value: this.$dayjs(item).valueOf(),
        });
      });
      this.tableLoading2 = true;
      getJoinList({
        id: this.selectedActivityId,
        date: this.$dayjs(this.selectDate).format("YYYY-MM-DD"),
      }).then((res) => {
        this.joinTableData = res.data.content.map((item) => {
          item.name = item.nickName;
          item.phoneNum = item.phone;
          item.joinTime = item.signInTime;
          item.joinStatus = item.status ? 4 : item.userType;
          return item;
        });
        this.tableLoading2 = false;
      });
      this.showJoinModal = true;
    },

    searchByOptions() {
      this.searchOptions.page = 1;
      this.searchOptions.activityName = this.searchActivityName;
      this.searchOptions.groupId = this.searchGroupID;
      this.searchOptions.type = this.searchType;
      this.getActivityList(this.searchOptions);
    },
    onSelectChange(e) {
      this.selectedRowKeys = e;
      this.selectedRowList = [];
      this.selectedRowKeys.map((item) => {
        this.selectedRowList.push(this.tableData[item]);
      });
    },
    getUploadToken() {
      return new Promise((resolve, reject) => {
        getSevenNiuToken("https://m2.zyh365.com/").then((res) => {
          this.uploadToken = res.data.token;
          this.uploadKey = res.data.key;
          this.uploadUrl = res.data.url;
          resolve();
        });
      });
    },
    getActivityList(
      params = {
        page: 1,
        pageSize: 20,
      }
    ) {
      this.tableLoading = true;
      this.selectedRowList = [];
      this.selectedRowKeys = [];
      getActivityList(params).then((res) => {
        if (res.status == 0) {
          this.tableData = res.data.content.map((item) => {
            item.timeCoin = item.timeMoney;
            item.createTime = item.addTime;
            item.join = item.joinPeopleCount == null ? 0 : item.joinPeopleCount;
            item.imageUrl = item.pics ? item.pics[0] : "";
            item.activityDate = item.dates.map((item) => {
              return moment(item);
            });
            return item;
          });
          this.total = res.data.total;
          this.tableLoading = false;
        }
      });
    },
  },
  data() {
    return {
      submitDisable2: false,
      previewImage: "",
      previewVisible: false,
      uploading2: false,
      tmpForm: {
        activityResult: "",
        picList: [],
      },
      activityRecordModal: false,
      peopleSearchKeyword: "",
      peopleSearchResult: [],
      addPeopleModal: false,
      tableLoading2: false,
      downloadLoading: false,
      selectedActivityId: "",
      jjj: "",
      buttonDisable: false,
      searchActivityName: null,
      searchGroupID: null,
      searchType: null,
      uploadToken: "",
      uploadKey: "",
      uploadUrl: "",
      modalTitle: "",
      total: 0,
      imageUrl: "",
      uploading: false,
      searchResult: [],
      timer: null,
      map: null,
      searchKeyWord: "",
      addFormSelectedDate: null,
      addFormSelectedDateList: [],
      addForm: {},
      getOrgive: [
        { title: "发币", value: 0 },
        { title: "扣币", value: 1 },
      ],
      addActivityForm: false,
      qrcode: null,
      activityName: "",
      joinExcleName: "",
      joinTableData: [],
      selectDate: null,
      activityLists: [],
      showJoinModal: false,
      searchOption: {
        page: 1,
        pageSize: 20,
      },
      allActivityType: [],
      selectedRowList: [],

      selectedRowKeys: [],
      tableLoading: false,
      tableData: [],
      searchOptions: {},
      allUserType: [
        { title: "长者", value: 1 },
        { title: "志愿者", value: 2 },
        { title: "管理员", value: 3 },
      ],
    };
  },

  created() {
    this.getUploadToken();
    this.getActivityList();
    getOptions({
      categoryId: 27,
      typeId: 0,
    }).then((res) => {
      this.allActivityType = res.data.content.map((item) => {
        item.title = item.name;
        item.value = item.id;
        return item;
      });
    });
  },
  mounted() {},
  computed: {
    ...mapState(["groupTree"]),
    rules() {
      return {
        activityName: [
          {
            required: true,
            message: "请输入活动标题",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择活动分类",
            trigger: "blur",
          },
        ],
        startTime: [
          {
            required: true,
            message: "请选择活动开始时间",
            trigger: ["blur", "change"],
          },
        ],
        endTime: [
          {
            required: true,
            message: "请选择活动结束时间",
            trigger: ["blur", "change"],
          },
        ],
        linkman: [
          {
            required: true,
            message: "请输入联系人",
            trigger: ["blur"],
          },
        ],
        tel: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: ["blur"],
          },
        ],
        moneyType: [
          {
            required: true,
            message: "请选择发币/扣币",
            trigger: ["blur"],
          },
        ],
        timeCoin: [
          {
            required: true,
            message: "请输入时间币个数",
            trigger: ["blur"],
          },
        ],
        address: [
          {
            required: true,
            message: "请选择活动地点",
            trigger: ["change"],
          },
        ],
        peopleCount: [
          {
            required: true,
            message: "请输入活动人数",
            trigger: ["blur"],
          },
        ],
        activityDate: [
          {
            required: true,
            message: "请选择活动日期",
            trigger: ["blur"],
          },
        ],
        gainTime: [
          {
            required: true,
            message: "请输入活动时数",
            trigger: ["blur"],
          },
        ],
        // crowd: [
        //   {
        //     required: true,
        //     message: "请输入面向对象",
        //     trigger: ["blur"],
        //   },
        // ],
        imageUrl: [
          {
            // required: true,
            message: "请上传招募图片",
            trigger: ["chnage"],
          },
        ],
        canOverage: [
          {
            required: true,
            message: "请选择是否可超额",
            trigger: ["change"],
          },
        ],
      };
    },
    joinExcelColumns() {
      return [
        { label: "姓名", field: "name" },
        { label: "手机号", field: "phoneNum" },
        {
          label: "参加日期",
          field: "joinTime",
          dataFormat: (e) => this.$dayjs(e).format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          label: "参与状态",
          field: "joinStatus",
          dataFormat: this.formatJoinStatus,
        },
      ];
    },
    joinTableColumn() {
      return [
        {
          dataIndex: "name",
          title: "姓名",
          align: "center",
        },
        {
          dataIndex: "phoneNum",
          title: "手机号",
          align: "center",
        },
        {
          dataIndex: "joinTime",
          title: "参加日期",
          align: "center",
          scopedSlots: { customRender: "joinTime" },
        },
        {
          dataIndex: "joinStatus",
          title: "参加状态",
          align: "center",
          scopedSlots: { customRender: "joinStatus" },
        },
      ];
    },
    tableColumns() {
      return [
        {
          dataIndex: "activityName",
          title: "活动名称",
          align: "center",
        },
        {
          dataIndex: "activityDate",
          title: "活动日期",
          align: "center",
          scopedSlots: { customRender: "activityDate" },
          width: 170,
        },
        {
          dataIndex: "startTime",
          title: "活动开始时间",
          align: "center",
        },
        {
          dataIndex: "endTime",
          title: "活动结束时间",
          align: "center",
        },
        {
          dataIndex: "groupName",
          title: "发布人",
          align: "center",
        },
        {
          dataIndex: "createTime",
          title: "发布时间",
          align: "center",
          scopedSlots: { customRender: "createTime" },
          width: 150,
        },
        {
          dataIndex: "join",
          title: "已参加人数",
          align: "center",
          scopedSlots: { customRender: "join" },
        },
        {
          dataIndex: "timeCoin",
          title: "消耗/获取时间币",
          align: "center",
          scopedSlots: { customRender: "timeCoin" },
        },
        {
          dataIndex: "status",
          title: "状态",
          align: "center",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 330,
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.search-result-container {
  margin-top: 1rem;
  max-height: 15rem;
  overflow-y: scroll;
  justify-content: center;
  border-bottom: 1px solid #eee;
  .search-result {
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #eee;
    &:last-of-type {
      border-bottom: none;
    }
    .search-result-btn {
      position: absolute;
      right: 3rem;
      top: 0;
      display: none;
    }
    &:hover {
      background: #e6f7ff;
      color: #1890ff;
      .search-result-btn {
        display: block;
      }
    }
  }
}
.search-row {
  display: flex;
  button {
    margin-left: 1rem;
  }
}
.title {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 22px;
}
.date-picker {
  display: flex;
  align-items: center;
}

.ant-card-body {
  padding: 0.56rem 1.06rem;
}
.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  border-radius: none !important;
}

.ant-table-row-cell-break-word {
  text-align: center !important;
}

.pagination {
  display: flex;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.actions .label {
  display: flex;
  align-items: center;
}
.actions .btns {
  display: flex;
  justify-content: space-between;
}
.excel-btn {
  padding: 0 !important;
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  vertical-align: middle;
}
.excel-btn:after {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.ant-tabs-tab {
  margin-right: 0;
}

.search-card {
  margin-bottom: 10px;
}
.activity-info {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-around;
}

#map-container {
  margin-left: 20px;
  width: 400px;
  height: 250px;
}

.search-container {
  position: absolute;
  top: 10px;
  left: 220px;
  width: 200px;
}
.search-list {
  background: #fff;
  overflow-y: auto;
  height: 180px;
}
.search-item {
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  padding: 0 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.search-item:hover {
  background: #e6f7ff;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>